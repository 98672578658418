.pricing-section {
    padding: 5% 10%;
    text-align: center;

    .pricing-header {
        margin-bottom: 40px;

        .title {
            font-weight: 700;
            margin: 20px 0;
            color: #fff;
            text-align: left;
            font-family: 'Poppins';
        }

        .description {
            max-width: 600px;
            margin: 0 auto;
            color: #fff;
            text-align: left;
            font-family: 'Poppins';
        }
    }

    .pricing-cards {
        display: flex;
        justify-content: center;
        gap: 40px;
    }

    .pricing-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 3px solid #fff;
        color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 4% 2%;
        width: 100%;
        max-width: 400px;
        position: relative;
        text-align: left;
        transform: translateY(100px);
        /* Initial position */
        opacity: 0;
        /* Initial visibility */
        transition: all 0.5s ease-out;

        &.animate {
            transform: translateY(0);
            opacity: 1;
        }

        &:hover {
            border: 3px solid #F02D27;
        }

        .plan-name {
            font-weight: 600;
            margin-bottom: 10px;
            width: fit-content;
            font-size: 2rem !important;
            font-family: 'Poppins' !important;
        }

        .plan-description {
            color: #777;
            margin-bottom: 20px;
        }

        .plan-price {
            font-size: 32px;
            font-weight: 700;
            color: #F02D27;
            margin-bottom: 20px;

            .period {
                font-size: 16px;
                font-weight: 400;
                color: #777;
            }
        }

        .features-list {
            list-style: none;
            padding: 0;
            margin: 20px 0;
            width: fit-content;

            .feature-item {
                font-size: 14px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                width: fit-content;

                .check-icon {
                    margin-right: 10px;
                    color: #F02D27;
                }
            }
        }

        .contact-button {
            max-width: 150px;
            background-color: #F02D27;
            color: #fff;
            font-weight: 600;
            padding: 10px 20px;
            border-radius: 5px;
            width: 100%;
            margin: 20px auto 0px auto;

            &:hover {
                background-color: #F02D27;
            }
        }

        .top-offer-badge {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #f5a623;
            color: #fff;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 5px;
        }
    }
}

.plan-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
}

@media (max-width: 1600px) {
    .pricing-content {
        width: 70% !important;
    }
}

@media (max-width: 1366px) {
    .pricing-section {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

}

@media (max-width: 600px) {
    .pricing-section .pricing-header {
        flex-direction: column !important;
    }

    .pricing-section .pricing-header .title {
        font-size: 2.75rem !important;
    }

    .pricing-section .pricing-cards {
        flex-direction: column;
        align-items: center;

        .pricing-card {
            padding: 10% 5%;

        }
    }

    .pricing-content {
        width: 100% !important;
    }
}