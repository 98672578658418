@media (max-width: 1281px) {
    .navbar-main {
        padding: 0.5% 9% !important;
    }

}

@media (max-width: 769px) {
    .navbar-main {
        padding: 0.5% 5% !important;
    }

}

.menu-bar {
    width: 80px;
    height: 2px;
    background-color: currentColor;
    display: block;
    position: relative;
    margin: auto;

    &::after {
        content: '';
        width: 80px;
        height: 2px;
        background-color: currentColor;
        position: absolute;
        left: 0;
        transition: all 0.6s ease;
        /* Increase duration to 0.6s for smoother transition */
    }


    &::after {
        bottom: -10px;
    }
}

.navbar-main:hover .menu-bar {
    transition: all 0.6s ease;
    /* Apply the same smooth transition on hover */
}