.title-about {
    font-weight: 700 !important;
    margin: 20px 0 !important;
    color: #fff !important;
    text-align: center !important;
    font-family: 'Poppins' !important;
}

.description-about {
    color: #fff;
    text-align: center;
    font-family: 'Poppins';
}

@media (max-width: 1366px) {
    .services-header-vision {
        padding-left: 5% !important;
        padding-right: 5% !important;

    }
}

@media (max-width: 600px) {
    .about-logos {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .services-header-vision {
        flex-direction: column;
    }

    .services-header-vision .header-image {
        order: 2;
    }
}