.input-field {
    border: 1px solid #8C8C8C !important;
    border-radius: 10px;
    padding: 15px 15px;
    background-color: transparent;
    width: 100%;
    color: #fff;
    font-size: 16px;
}

label {
    font-family: 'Poppins';
    color: #fff;
    font-size: 18px;
}

.number-div {
    border-radius: 10px;
    padding: 2% 3%;
    border: 1px solid #fff;
    font-size: 18px;
}

.number-div .num-text {
    font-size: 18px !important;

}

@media (max-width: 600px) {
    .contact-form-main {
        flex-direction: column;
    }

    .contact-form-main div {
        width: 100%;
    }

    .contact-fields-outer {
        gap: 1rem !important;
    }
}

@media (max-width: 450px) {
    label {
        font-family: "Poppins";
        color: #fff;
        font-size: 12px;
    }

    .input-field {
        font-size: 12px;
    }

    .number-div .num-text {
        font-size: 10px !important;
    }

    .number-div .number-icon{
        width: 0.5em !important;
        height: 0.5em !important;
    }
}