/* ChatIcon.css */
.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #F02D27;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-decoration: none;
}