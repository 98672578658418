.comingSoon-main {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('../../Assets/BackgroundImage_11zon.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .dots {
        display: inline-block;

        span {
            opacity: 0;
            animation: blink 1.5s infinite;
        }

        span:nth-child(1) {
            animation-delay: 0s;
        }

        span:nth-child(2) {
            animation-delay: 0.5s;
        }

        span:nth-child(3) {
            animation-delay: 1s;
        }
    }

    @keyframes blink {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}