.bg-image{
    opacity: 0.5;
}

@media (max-width: 600px) {
    .heading-text {
        font-size: 44px !important;
        line-height: 40px !important;
        letter-spacing: 0px !important;
    }
}