.stats-main {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 5%;
    padding: 10%;

}

@media(max-width: 1366px) {
    .stats-main {
        padding: 5%;

    }
    .count-heading {
        font-size: 15px !important;
    }
}

@media(max-width: 600px) {
    .count {
        font-size: 24px !important;
    }

    .count-heading {
        font-size: 12px !important;
    }

    .stats-main {
        padding: 10% !important;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}