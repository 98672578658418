@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}
.arrow-div {
  bottom: -8% !important;
}

// @keyframes blink-caret {

//   from,
//   to {
//     border-color: transparent
//   }

//   50% {
//     border-color: white
//   }
// }

.innovation-typing {
  white-space: nowrap;
  overflow: hidden;
  // border-right: 4px solid;
  // animation: blink-caret 0.75s step-end infinite;
}

// .typing-text {
//   display: inline-block;
//   animation: typing 3s steps(10), blink-caret 0.75s step-end infinite;
// }

@keyframes change-text {
  0% {
    content: "CONNECT";
  }

  33% {
    content: "INNOVATE";
  }

  66% {
    content: "COLLABORATE";
  }

  100% {
    content: "CONNECT";
  }
}

.typing-text::before {
  content: "CONNECT";
  animation: change-text 4s infinite;
}


@media(max-width: 600px) {
  .typing-text {
    font-size: 44px !important;
    line-height: 40px !important;
  }

  .hero-subheading {
    font-size: 12px !important;
  }

  .hero-text {
    gap: 10px !important;
  }

  .hero-video {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .home-about-main {
    margin: 15px auto 30px auto !important;
  }

  .home-about-inner {
    flex-direction: column;
    gap: 0 !important;
  }

  .home-about-inner-left,
  .home-about-inner-right {
    width: 100% !important;
  }

  .arrow-div {
    bottom: -8% !important;
  }

  .pricing-content {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .innovation-typing {
    line-height: 45px !important;
  }
}