.explore-observatory {
    background-color: #000;
    color: #FFF;
    padding: 5% 5%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .sub-header {
            font-size: 14px;
            letter-spacing: 2px;
        }

        .main-header {
            font-size: 48px;
            font-weight: 700;
            font-family: 'Poppins' !important;
        }

        .learn-more {
            font-size: 16px;
            color: #FFF;
            text-decoration: none;
            border-bottom: 1px solid #FFF;
            padding-bottom: 2px;

            &:hover {
                color: #FFF;
            }
        }
    }

    .service-grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-around;
    }

    .service-card {
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;
        max-width: 500px;
        flex: 1;
        transition: transform 0.3s ease;

        .service-card :first-child {
            margin-left: 30%;
        }

        &:hover {
            transform: scale(1.05);
        }

        .service-image {
            width: 150px;
            height: 155px;
            display: block;
            border-radius: 100%;
        }

        .service-overlay {
            position: absolute;
            bottom: -50px;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6);
            padding: 20px;
            opacity: 0;
            transition: all 0.3s ease;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .arrow-icon {
                font-size: 24px;
                font-weight: 700;
                color: #FFF;
            }

            .border-animation {
                position: absolute;
                bottom: -1px;
                left: 0;
                height: 2px;
                background-color: #FFF;
                transition: width 1s ease-in-out;
            }

            @keyframes width {
                0% {
                    width: 0%;
                }

                100% {
                    width: 100%;
                }

            }
        }
    }
}

.textAnimation {
    overflow: hidden;
    position: relative;
}

.scrolling-text {
    font-family: 'Poppins' !important;
    display: inline-block;
    animation: scroll-left 500s linear infinite;
}

@keyframes scroll-left {
    0% {
        transform: translateX(0%);
        /* Start position off the screen to the right */
    }

    100% {
        transform: translateX(-100%);
        /* End position off the screen to the left */
    }
}

.chapters-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading-text {
    font-family: "Poppins";
    font-size: '120px';
    font-weight: '700';
    text-transform: 'uppercase';
    line-height: '105px';
    letter-spacing: '-5px';
    color: '#FFF';
    white-space: 'nowrap';
    overflow: 'hidden';
}

.service-grid-mob {
    display: none !important;
}

@media (max-width: 1366px) {
    .explore-observatory {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

@media (max-width: 800px) {
    .sub-header {
        font-size: 10px !important;
        letter-spacing: 2px;
    }

    .main-header {
        font-size: 2.75rem !important;
        font-weight: 700;
    }

    .city-heading {
        font-size: 20px !important;
    }

    .city-subheading {
        font-size: 14px !important;
    }

    .service-grid-mob {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1em;
    }

    .chapters-circle {
        flex-direction: column;
        gap: 2rem;
    }

    .explore-observatory .service-grid {
        display: none !important;
    }

    .explore-observatory .service-card {
        margin-left: 0 !important;
        justify-content: flex-start !important;
        flex-direction: column;
    }

    .chapter-logo {
        display: none;
    }

    .explore-observatory .service-card .service-image {
        border-radius: 10px;
    }

    .detail-section {
        margin-top: 25%;
    }

    .detail-heading {
        font-size: 2.75rem !important;
        line-height: 1.2 !important;
    }

    .heading-outer-section {
        padding: 23% !important;
    }
}