@media (max-width: 1366px) {
    .footer-main {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

@media (max-width: 600px) {
    .footer-header {
        flex-direction: column;
        gap: 10px;
    }

    .logo-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-footer {
        flex-direction: column;
        gap: 1rem;
    }
}