@media (max-width: 1366px) {
    .articles-main {
        padding-left: 5% !important;
        padding-right: 5% !important;

    }

    .heading-text-article {
        font-size: 32px !important;
        line-height: 40px !important;
        letter-spacing: 0px !important;
    }
}

@media (max-width: 1024px) {
    .heading-text-article {
        font-size: 20px !important;
        line-height: 40px !important;
        letter-spacing: 0px !important;
    }
}

@media (max-width: 600px) {
    .learn-more {
        font-size: 12px;
    }

    .articles-heading {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .articles-grid {
        grid-template-columns: 1fr;
    }

    .heading-text-article {
        font-size: 14px !important;
        line-height: 17px !important;
        letter-spacing: 0px !important;
    }
}