.product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 0% 10% 5% 10%;


    .product-card:first-child {
        grid-row: span 2;
    }
}

.product-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    &:hover .product-overlay {
        opacity: 1;
    }

    &:hover img {
        transform: scale(1.1);
    }
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-overlay Button {
    color: #FFF;
    background: transparent;
    border: 1px solid #FFF;
}

.main-header {
    font-family: 'Poppins' !important;
}

@media (max-width: 1366px) {

    .main-header-outer,
    .product-grid {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

@media(max-width: 600px) {
    .main-header {
        font-size: 2.75rem !important;
    }

    .main-header-outer {
        margin-bottom: 20px !important;
    }

    .product-heading {
        font-size: 18px !important;
    }

    .product-description {
        font-size: 10px !important;
    }

    .product-grid {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}