@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


.lower-section {
    position: relative;
    z-index: 3;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: .5;
    filter: blur(2px);
}

.activeSlide {
    transform: scale(1);
    opacity: 1;
    filter: none;
}

// .card {
//     border-radius: 20.792px;
//     width: 350px;
// }


.lower-section .slick-dots {
    bottom: -30%;
    color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #F02D27;
    border-radius: 100px;
    font-size: 10px;
    border: 1px solid #F02D27;
}

.slick-dots {
    width: 98%;
}

.slick-dots li button:before {
    color: #fff;
    font-size: 9px;
}

.slick-dots li {
    margin: 0px;
}

@media (max-width:1680px) {
    .lower-section {
        width: 62%;
    }
}

@media (max-width:1650px) {}

@media (max-width:1584px) {
    .lower-section {
        width: 66%;
    }
}

@media (max-width: 1440px) {
    .lower-section {
        width: 72%;
    }

}

@media (max-width:1366px) {
    .lower-section {
        width: 85%;
    }

    .upper-left {
        gap: 0px;
    }

    .upper-section h2 {
        margin-top: 3%;
        margin-bottom: 36px;
    }

    .upper-section p {
        margin-bottom: 36px;
    }
}

@media (max-width:1280px) {
    .lower-section .slick-dots {
        width: 100%;
    }





}

@media (max-width:1024px) {
    .upper-section h1 {
        font-size: 40px;
    }

    .upper-section h2 {
        font-size: 50px;
    }

    .upper-section p {
        font-size: 14px;
        width: 100%;
    }

    .lower-section {
        width: 98%;
    }
}

@media (max-width:820px) {
    .upper-section h1 {
        font-size: 33.186px;
    }

    .upper-section h2 span {
        font-size: 47.38px;
    }

    .upper-section h2 {
        font-size: 47.38px;
    }

    .upper-section p {
        font-size: 14px;
        width: 84%;
    }


    .upper-section {
        padding-left: 16px;
        padding-right: 16px;
        flex-direction: column;
    }

    .upper-left {
        width: 100%;
    }

    .middle-section h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.675px;
        letter-spacing: -0.32px;
        padding-bottom: 5px;
    }

    .middle-section p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.51px;
    }

    .lower-section {
        width: 92%;
        padding-top: 15%;
    }

    .card {
        width: 256px;
    }

    .main-wrapper {
        padding-bottom: 25%;
    }

    .lower-section .slick-dots {
        bottom: -20%;
        color: #29B34E;
    }

}

@media (max-width:431px) {
    .lower-section {
        width: 60%;
        padding-top: 15%;
    }

    .card {
        width: 256px;
    }

    .main-wrapper {
        padding-bottom: 25%;
    }

    .lower-section .slick-dots {
        bottom: -20%;
        color: #29B34E;
    }

}

@media (max-width: 415px) {
    .lower-section {
        width: 64%;
        padding-top: 15%;
    }
}

@media (max-width:391px) {
    .card {
        width: 245px;
    }
    .lower-section{
        width: 66%;
    }
}
@media (max-width: 360px) {
    .lower-section {
        width: 75%;
        padding-top: 15%;
    }

    .card {
        width: 270px;
    }
}